.services {  
   object-fit: cover;
   background-image: url("../../../../assets/backgrounds/services-background.png");
   background-size: cover;
   background-repeat: no-repeat;
   width: 100vw;
   padding-bottom: 4rem; 
 

      @include query(max-w-1000) {
         background-position: 20% 20%;
      }
      
      @include query(max-w-700) {
         background-position: 30% 20%;
      }

   &__container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      
      
   }

 
  

   &__topBox {
      
      border-radius: .8rem;
      color: $white;
      display: flex;
      justify-content: space-around;

      & h3 {
         font-size: 2.8rem;
         font-weight: 700;
      }
   }

   &__bottomBoxes {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 4rem; 
      width: 100%;     


      @include query(max-w-1500) {
         column-gap: 2rem;
      }

      @include query(max-w-1300) {
         column-gap: 1rem;
         
      }

   }

   &__smBox {
      margin-bottom: 4rem;      
      border: 1px solid $gray;     
      border-radius: .8rem;
      color: $black;
      display: flex;
      flex-direction: column;
      width: 55rem;
      // box-shadow: rgba(201, 46, 240, 0.4) 0px 5px, rgba(201, 46, 240, 0.3) 0px 10px, rgba(201, 46, 240, 0.2) 0px 15px, rgba(201, 46, 240, 0.1) 0px 20px, rgba(201, 46, 240, 0.05) 0px 25px;
      box-shadow: rgba(255,255,255,.07) 0px 1px 2px, rgba(255,255,255,.07) 0px 2px 4px, rgba(255,255,255,.07) 0px 4px 8px, rgba(255,255,255,.07) 0px 8px 16px, rgba(255,255,255,.07) 0px 16px 32px, rgba(255,255,255,.07) 0px 32px 64px;
      background-image: url("../../../../assets/backgrounds/sheat2.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      
       @include query(max-w-1500) {
         width: 50rem;
      }

      @include query(max-w-1300) {
         width: 45rem;
      }

      @include query(max-w-1000) {
         width: 100%;
      }

      &--title {
         text-align: center;
         border-bottom: 1px solid $black;
         padding: 2rem 0;
         
         & p {
            font-size: 2rem;
            font-weight: 800;
         }
         
      }

      &--imgContainer {

         & img {
            width: 100%;
            height: 150px;
            object-fit: cover;
         }
      }



      &--description {
         // margin-top: 2rem;
         padding: .5rem 2rem;
         // min-height: 22rem;

         & p {
            font-size: 1.6rem;
            font-weight: 800;
         }

      @include query(max-w-1000) {
         min-height: 100%;
       }

      }

   
      
      &--link {
         padding: 1rem 0 2rem 0;         
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
      }

      &--link:hover {
         color: $orange
      }

      


   }
}