.hero {
   position: relative;
   min-height: 100vh;
   width: 100vw;
   color: $white;
   object-fit: cover;
   background-image: url("../../../../assets/backgrounds/bg-6.jpg"); 
   background-size: cover;
   background-repeat: no-repeat;
   margin-top: 80px;

   @include query(max-w-1000) {
      background-position: 20% 20%;
   }

   @include query(max-w-700) {
      background-position: 30% 20%;
   }
   
   

   &__container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      min-height: 100vh;
      padding: 6rem;

      @include query(max-w-700) {
         padding: 2rem;
      }

      @include query(max-w-600){
         justify-content: flex-start;
         padding-top: 8rem;
      }

      & h1 {
         text-shadow: 2px 2px $black;


         @include query(max-w-400) {
            font-size: 3rem;
            
         }
      }
     

      & h2 {
         font-size: 2.4rem;
         font-weight: 400;
         text-shadow: 2px 2px $black;

         @include query(max-w-400) {
            font-size: 2.4rem;
         }
      
      }
   }

   &__line {
      height: 2px;
      border-top: 1px solid $gray ;
      width: 20rem;
      margin-top: 4rem;
      margin-bottom: 1rem;
   }

   &__subTitle {
      margin-top: 2rem;
      width: 60%;

      @include query(max-w-1000) {
         width: 90%;
      }

      @include query(max-w-480) {
         width: 100%;
      }

      & h3 {
         margin-bottom: 2rem;
      }

      & p {
         font-size: 1.6rem;
      }
   }

   &__btnContainer {
      width: 100%;
      padding: 4rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 2rem;

      @include query(max-w-480){
         flex-direction: column;
         row-gap: 2rem;
      }
   }
}