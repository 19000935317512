.navBar {
   background-color: $black;
   width: 100vw;
   height: 80px;
   color: $white;

   position: fixed;
   top: 0;
   z-index: 5;

   &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
   }

   
   &__left {
      width: 20%;
      display: flex;
      padding-left: 1.5rem;
   }

   &__logo{
      height: 50px;
      object-fit: contain;
      cursor: pointer;
   }


   &__right {
      
      width: 80%;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;

      @include query(max-w-775) {
         display: none;
      }
   }

   &__items {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      row-gap: 8px;

      & a {
         padding-right: 28px;
         font-size: 18px;
         transition: all .3s;
         color: $white;
      }

      & a:hover {
         cursor: pointer;
         color: $orange;
      }
   }

   &__btnContainer {
      margin-top: 2rem;
   }
   &__subNavBar{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: all .3s;
   
      & p{
         padding: 1rem 2rem;
         background-color: $black;
         color: #FFF;
      transition: all .3s;


         &:hover {
            color: $orange;
         }
      }
   }
}