//Remove browsers default margin and padding
*,
*::before,
*::after {
   margin: 0;
   padding: 0;
   box-sizing: inherit;
   scrollbar-width: none; //Remove scroll bar  
}

//Remove scroll bar for cleaner design
::-webkit-scrollbar {
   width: 0;
   /* Remove scrollbar space */
   background: transparent;
   /* Make scrollbar invisible */
}

html {
   // Set up for rem use -  10px/16px = 62.5%
   font-size: 62.5%; //  1 rem = 10px


   //responsive design queries for REM sizes
   @include query(max-w-1200) {
      font-size: 56.25% // 1rem = 9px - 9/16 = 56.25%
   }

   @include query(max-w-900) {
      font-size: 50% // 1 rem = 8px - 8/16 = 50%
   }

   @include query(min-w-1800) {
      font-size: 75%;
   }
}

body {
   //On an element, padding and border are included in the width and height, making it easier to calculate sizes
   box-sizing: border-box;
}


input {
   outline: none;
   width: 100%;
   min-height: 3.5rem;
   padding: 1rem;
   border: 2px solid rgba(#ffffff, .01);
   background-color: rgba(#EFEFEF, .0);
   // background-color: rgba(#EFEFEF, .4);

   border-radius: 2px;
   transition: all .2s;
   font-size: 1.6rem;
   margin: 1rem 0;
   font-family: $font-text;
   transition: all .4s;
   margin: 1rem 0;font-weight: 600;

   
}

textarea {
   outline: none;
   border: 2px solid rgba(#ffffff, .01);
   background-color: rgba(#EFEFEF, .0);
   width: 100%;
   height: 10rem;
   border-radius: 2px;

   resize: none;
   margin: 1rem 0;font-weight: 600;
   transition: all .4s;
   font-family: $font-text;
   padding: 1rem;

    }


input:focus,
textarea:focus,
select:focus {    
   border: 2px solid rgba($orange, .5);
   border-radius: 2px;
   // background-color: rgba(#EFEFEF, 1); 

}



//Clear browsers default styling
button {
   background: none;
   outline: none;
   border: none;
   cursor: pointer;
}

.btnMain {
   width: 220px;
   height: 50px;
   border: none;
   outline: none;
   color: #fff;
   background: #111;
   cursor: pointer;
   position: relative;
   z-index: 0;
   border-radius: 10px;

   &:before {
      content: '';
      background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      border-radius: 10px;
   }

   &:hover {
         transform: scale(1.01);
      }
   
      &:active {
         transform: scale(.99);
      }
   
      &:hover:before {
         opacity: 1;
      }
   
      &:after {
         z-index: -1;
         content: '';
         position: absolute;
         width: 100%;
         height: 100%;
         background: #111;
         left: 0;
         top: 0;
         border-radius: 10px;
      }
}

@keyframes glowing {
   0% {
      background-position: 0 0;
   }

   50% {
      background-position: 400% 0;
   }

   100% {
      background-position: 0 0;
   }
}


.checkboxContainer {
   display: block;
   position: relative;
   padding-left: 3.5rem;
  
   cursor: pointer;
   font-size: 1.4rem;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 
 /* Hide the browser's default checkbox */
 .checkboxContainer input {
   position: absolute;
   cursor: pointer;
   opacity: 0;
   height: 0;
   width: 0;
 }
 
 /* Create a custom checkbox */
 .checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 25px;
   width: 25px;
   background-color: rgba($white, .5);
   border-radius: 4px;
   border: 1px solid #ddd
 }
 
 /* On mouse-over, add a grey background color */
 .checkboxContainer:hover input ~ .checkmark {
   background-color: rgba($white, .60);
 }
 
 /* When the checkbox is checked, add a blue background */
 .checkboxContainer input:checked ~ .checkmark {
   background-color: $orange;
   border: 1px solid $orange
 
 }
 
 /* Create the checkmark/indicator (hidden when not checked) */
 .checkmark:after {
   content: "";
   position: absolute;
   display: none;
 }
 
 /* Show the checkmark when checked */
 .checkboxContainer input:checked ~ .checkmark:after {
   display: block;
 }
 
 /* Style the checkmark/indicator */
 .checkboxContainer .checkmark:after {
   left: 9px;
   top: 4px;
   width: 7px;
   height: 13px;
   border: solid white;
   border-width: 0 3px 3px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
 }


 .section {
   min-height: 100vh;
   width: 100vw;     
   padding-bottom: 6rem;
   padding: 4rem 6rem;

   @include query(max-w-550) {
      padding: 2rem .5rem;
   }
 }
