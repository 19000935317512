.navigation {

   display: none;

   @include query(max-w-775) {
      display: inline;
   }

   &__logoContainer {
      margin: 5rem 0;      
      text-align: center;


      img {
         width: 150px;
      }
   }
   
   &__checkbox {
      display: none;
   }

   &__button {
      background-color: $white;
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      position: fixed;
      top: 2rem;
      right: 2rem;
      z-index: 200;
      box-shadow: 0 1rem 3rem rgba($black, 0.1);
      text-align: center;
      cursor: pointer;


   }

   &__background{
      height: 4rem ;
      width: 4rem;
      border-radius: 50%;
      position: fixed;
      top: 2.5rem;
      right: 2.5rem;
      background-color:  $black;
      z-index: 100;
      transition: transform 0.5s cubic-bezier(0.83, 0, 0.17, 1);


   
   }

   &__nav {
      height: 100vh;
      position: fixed;
      top: 0;
      right: 0; //Choose wich side it comes from for the animation
      z-index: 150;
      width: 0; //Start Hidden
      opacity: 0;
      transition: all 0.3s ;

   }

   &__list {
      width: 100%;
      @include translateCenter;
      list-style: none;
      text-align: center;
      
   }
   
   &__item {
      margin: 1rem;
      
   }
   
   &__link {
      &:link,
      &:visited {

         display: inline-block;
         font-size: 3rem;
         font-weight: 300;
         padding: 1rem 2rem;
         color: $white;
         text-transform: uppercase;
         text-decoration: none;
         background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $orange 50%);
         background-size: 230%;
         transition: all 0.4s;

         @include query(max-w-900){
            font-size: 2.5rem;
         }

      }

      &:hover,
      &:active {
      background-position: 100%;
      color: $black;
      transform: translateX(1rem)
      }
   }

   //FUNCTIONALITY
   &__checkbox:checked ~ &__background {
      transform: scale(90);
   }

   &__checkbox:checked ~ &__nav {
      opacity: 1;
      width: 100%;
   }


   //ICON
   &__icon {
      position: relative;
      margin-top: 2.4rem;


      &,
      &::before,
      &::after {
         width: 2rem;
         height: 2px;
         background-color: $gray;
         display: inline-block;
         
      }

      &::before,
      &::after {
         content: "";
         position: absolute;
         left: 0;
         transition: all .2s;
      }

      &::before {top: -.8rem}
      &::after {top: .8rem}



   }

   //When Hovered the before and after move slightly up and down accordigly
   &__button:hover &__icon::before {
      top: -1rem;
   }

   &__button:hover &__icon::after {
      top: 1rem;
   }


   //When clicked hides the middle bar 
   &__checkbox:checked + &__button &__icon {
      background-color: transparent;
   }


   //when clicked rotate each to end up in an x
   &__checkbox:checked + &__button &__icon::before {
      top: 0;
      transform: rotate(135deg);
   }

   &__checkbox:checked + &__button &__icon::after {
      top: 0;
      transform: rotate(-135deg)
   }


}