.footer {
   width: 100%;   
   background-color: #000;
   color: $white;
   padding: 1rem;

   &__container {
      padding: 0 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      min-height: 100px;

      @include query(max-w-600){
         flex-direction: column;
         justify-content: center;
         row-gap: 4rem;
         padding: 2rem 0;
      }

   }

   &__left {

   }

   &__logoContainer {
      padding-bottom: .5rem;
      margin-bottom: .5rem;
      border-bottom: 1px solid $white;
   }

   &__right {
  
     
   }

 

   
}