.modal-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.788);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10;
}

.modal-content {
   object-fit: cover;
   background-image: url("../../assets/backgrounds/sheat2.jpg");
   background-size: cover;
   background-repeat: no-repeat;
   // margin: 10%;
   border: 1px solid #888;
   width: 80%;
   height: 90%;   
   position: relative;
   border-radius: 18px;
   overflow: hidden;
   // background-color: yellow;

   @include query(max-w-355){
      width: 95%;
      height: 95%;
   }
   
}


.modal {

   &__container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      
      @include query(max-w-900){
         flex-direction: column;
      }
   }

   &__imgContainer {
      width: 50%;
      height: 100%; 

      @include query(max-w-900){
         width: 100%;
         height: 40%;
      }

      @include query(max-w-480){
         height: 30%;
      }

      @include query(max-w-400){
         height: 15%;
      }


      @include query(max-w-355){
         // display: none;
      }
   }

   &__right {
      width: 50%;

      @include query(max-w-900){
         width: 100%;
         height: 60%;
      }

      @include query(max-w-480){
         height: 70%;
         width: 100%;
      }
   }


   &__titleContainer {
         padding: 2rem 1rem 1rem 1rem;
         font-weight: 700;
   
         & p {
            font-size: 2.4rem;
         }
   
         & div {
            color: rgba($black, .4);
            font-size: 1.8rem;
            display: flex;

            & img {
               margin-right: .2rem;
               width: 25px;
            }
         }
      }
   
   &__descriptionContainer {
      padding: 1rem 2rem;

      & p {
         font-size: 1.6rem;
         text-align: justify;
      }
   }
   
   &__btnContainer {
      width: 100%;
      margin-bottom: 1rem ;
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--btn {
         background: $light-gray;
         border-radius: 8px;
         width: 100%;
         // padding: 1rem 0;
         border: 1px solid $gray;
         box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
         cursor: pointer;

         & p {
            font-weight: 700;
            font-size: 1.8rem;
         }

         &:hover {
            background: $light-gray;
         }
      }

      &--seats {
         color: $orange;
         font-size: 2.8rem;
      }
   }
}