.newPlayerForm{  
  object-fit: cover;
  background-image: url("../../assets/backgrounds/bg-5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 80px;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
 
 }

&__description {

  & p {
    color: $white;
    font-size: 2.6rem;
  }
}

&__formContainer {      
  margin: 6rem 0;      
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  
 
} 

&__form {       
  width: 75%;
  background-image: url("../../assets/backgrounds/sheat2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 4rem;
  border-radius: 18px;
    
    &--double{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 2rem;

      @include query(max-w-850){

        flex-direction: column
      }
    }       
}

     
  &__inputContainer {

   background-color: rgba(#EFEFEF,0.4);
   // border-radius: 1rem/15rem;
   border: 1px solid rgba(0,0,0,0.5);
   width: 100%;
   margin: 1rem 0;
   padding: 1.5rem;


   & label {
     font-size: 1.6rem;
     font-weight: 800;
     // text-shadow: 2px 2px 1px $white;
   }

   & input {
     
  
   
   }

   & textarea {
  
    

   }    

   &--multiCheckBox  {
     padding-top: 2rem;
     display: flex;
     flex-direction: column;
     justify-content: "center";
     align-items: flex-start;
     row-gap: 1rem;
   }
  }





 &__weekDayRow {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   // column-gap: 1rem;

   & select {
     width: 12rem;
     padding: 1rem;
     margin: 1rem 2rem; 
     min-height: 3.5rem;
     display: inline-block;
     border: 1px solid rgba(#ffffff, .7);
     background-color: rgba(#EFEFEF, .4);
     border-radius: 4px;
     box-sizing: border-box;
     font-size: 1.6rem;
     font-family: $font-text;

   }

   &--day {
     width: 8rem;
   }
 }

 &__inputRequired {
   color: #ea0a0a;
   margin-left: 1rem;
   font-size: 1.2rem;
 }

 &__submitted {
   margin-top: 1rem;
   padding: 2rem;
   background-color: rgba(#EFEFEF, .4);
   border: 1px solid $orange;
   font-size: 1.8rem;
   border-radius: 8px;
 }

 &__btnFinished {
   color: $black;
 }


 &__btnContainer{
   width: 100%;
   padding-top: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
 }
}



