.twitchBtn {
   min-width: 300px;
   min-height: 100px;
   border: none;
   outline: none;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 0;
   position: relative;
   cursor: pointer;
   background: #FFF;
   border-radius: 18px;
   padding: 1rem 4rem;
   box-shadow: 8px 8px 18px rgba(0,0,0,.5);

   & p {
      font-size: 18px;
      font-family: $font;
      font-weight: 800;
   }

   &--left {
      margin-right: 2rem;
   }
   

   &:before {
      content: '';
      background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      border-radius: 10px;
      transition: all .4s;
      }
      
      &:hover{
         transform: scale(1.01);
      }

      &:active {
         color: #000;
         transform: scale(.99);                  
      }

      
      &:hover:before {
         opacity: 1;
      }

      &:after {
         z-index: -1;
         content: '';
         position: absolute;
         width: 100%;
         height: 100%;
         background: #FFF;
         left: 0;
         top: 0;
         border-radius: 10px;
      }
}