.dms {
   object-fit: cover;
   background-image: url("../../../../assets/backgrounds/meet-the-dms.png");
   background-size: cover;
   background-repeat: no-repeat;
   

   &__container {     
      width: 100%;     
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 4rem;
   
   }

   &__cardContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
   }

   &__box {
      width: 75%;
      margin-top: 2rem;
      background-color: $white;
      border-radius: 8px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-image: url("../../../../assets/backgrounds/sheat2.jpg");
      background-size: cover;
      background-repeat: no-repeat;

      @include query(max-w-700) {
         flex-direction: column;
      }
        
   }


   &__smContainer {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 45%;

      @include query(max-w-700) {
            width: 100%;
         }

      & img {
         width: 100%;
         padding-top: 2rem;
      }

   }
   
   &__textContainer {
      padding: 2rem;
      & h3 {
         padding: 1rem 0;
      }
      & p{
         padding-bottom: 4rem;
      }
   }


}