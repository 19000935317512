.currentCampaigns {       
      object-fit: cover;
      background-image: url("../../../../assets/backgrounds/bg-3.png");
      background-size: cover;
      background-repeat: no-repeat;

      &__subTitle {
         color: $white;
      }

   &__container {     
         width: 100%;
         display: flex;
         justify-content: flex-start;
         align-items: flex;
         flex-direction: column;
         flex-wrap: wrap;
         row-gap: 4rem;

       
         
         @include query(max-w-1000){
            justify-content: center;
         }    
      }
      
      &__titleContainer {

            padding-top: 2rem;
      }

      &__cardsContainer{
         display: flex;
         justify-content: space-around;
         align-items: center;
         flex-wrap: wrap;
      }

      &__btnContainer {
         width: 100%;
         margin-top: 4rem;
         display: flex;
         justify-content: center;
         align-items: center;
      }
}