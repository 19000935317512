.twitch {
      padding-bottom: 6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      background-color: #6441a4;
      object-fit: cover;
      // background-image: url("../../../../assets/backgrounds/twitchBg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 100vh;

      &__titleContainer{
         margin-top: 4rem;
      }

      &__container {
            padding: 4rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 1rem;
         }

         &__gallery {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            column-gap: 2rem;
            row-gap: 2rem;

            & img {
               width: 40%;
               min-width: 40rem;
               box-shadow: 3px 15px 8px -10px rgba(255, 255, 255, 0.3);
               border: 1px solid #FFF;
               border-radius: 8px;

               @include query(max-w-480){
                  min-width: 35rem;
               }
               
            }

            margin: 4rem 0;
         }


    
}




@keyframes glowing {
   0% {
      background-position: 0 0;
   }

   50% {
      background-position: 400% 0;
   }

   100% {
      background-position: 0 0;
   }
}