.campaigns {
   margin-top: 80px;
   

   &__container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 4rem;
      position: relative;

      @include query(max-w-1000){
         justify-content: center;
      } 
   }

   &__titleContainer {
      margin: 4rem 0;

      & p {
         width: 75%;
      }
   }


   &__cardsContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      // background-color: rgba(246, 246, 246, 0.556);
      padding: 1rem 0 ;
      
      border-radius: 16px;
      // box-shadow: 2px 2px 18px rgba(0,0,0,.5);

   }
}