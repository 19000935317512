//Fonts
$font: 'Cinzel Decorative', cursive;
$font-text: 'Raleway', sans-serif;;

//Colors
$black: #000;
$white: #FFF;
$gray: #999;
$light-gray: #D9D9D9;

$orange: #ffae0c;
$orange-hover: #ffd88b;