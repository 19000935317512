.socialIcons {
   color: white;
   transition: all .4s;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 2rem;

   &--F {

      &:hover {
         color: #4267B2;
      }
   }

   &--I {

      &:hover {
         color: #F77737;
      }
   }

   
   &--T {
      
      &:hover {
         color: #6441a5;
      }
   }

   &--D {

      &:hover {
         color: #5865F2;
      }
   }

   &--Y {

      &:hover {
         color: #FF0000;
      }
   }
}