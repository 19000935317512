body {
   font-weight: 600;
   line-height: 1.7;
   font-family: $font-text;
   word-spacing: 3px;
}

a {
   cursor: pointer;
   text-decoration: none;
   transition: all .3s;
}

h1 {
   font-size: 6rem;
   font-weight: 600;
   font-family:$font;
}

h2 {
   font-size: 3.6rem;
   font-family:$font;

}

h3 {
   font-size: 1.8rem;
   font-family:$font;
}

h4 {
   font-size: 1.8rem;
   font-family:$font;
}

h5 {
   font-size: 1.6rem;
   font-family:$font;
}

p {
   font-size: 1.4rem;
   font-family: $font-text;
   font-weight: 600;
}

li {
   font-size: 1.4rem;
   list-style: none;
   font-family: $font-text;
   font-weight: 600;
}