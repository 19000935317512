.campaignCard{
   margin: 1rem;
   background-image: url("../../assets//backgrounds/sheat2.jpg");
   background-size: cover;
   background-repeat: no-repeat;
   width: 30rem;
   border-radius: .8rem;
   box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
   border: 1px solid #000000;
   border-radius: 8px;
   overflow: hidden;
   transition: all .4s;

   &:hover{
      box-shadow: rgba(255, 255, 255, 0.586) 0px 0px 20px 5px;

   }

   @include query(max-w-700) {
      width: 80%;
      margin-bottom: 3rem;
   }

   @include query(max-w-480) {
      width: 100%;
      margin-bottom: 3rem;
   }

   &__container {
      padding: 1.5rem;

   }
   
   &__click {
      cursor: pointer;
   }


   &__imgContainer {

      position: relative;
      &--img {
         width: 100%;
         height: 200px;
         object-fit: cover;
         
      }

      &--seatsContainer{
         display: flex;
         justify-content: center;
         align-items: center;
         padding: .5rem 2rem;
         width: 200px;
         background: rgba(255, 0, 0, .8);
         position: absolute;
         top: 22px;
         right: -45px;
         color: white;
         font-weight: 500;

         transform: rotate(40deg);
      }

      &--seats {
         color: $white;
         // text-shadow: 2px 1px 2px $black;
         font-size: 1.6rem;
      }
   }

   &__titleContainer {
      margin-bottom: 1rem;
      font-weight: 700;
      border-bottom: 1px solid rgba(148, 148, 148, 0.5);
      
      & p{
         font-size: 1.8rem;

      }

      & div {
         color: rgba($black, .6);
         font-size: 1.4rem;
         display: flex;
         
         & img {
            margin-right: .2rem;
            width: 25px;
         }
      }
   }
   




   &__descriptionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & p {
         font-size: 1.4rem;
         font-weight: 600;
         text-align: justify;
      }

      & span {
         margin: .5rem 0 1rem 0;
         font-weight: 800;
         font-size: 1.6rem;
         cursor: pointer;
         transition: all .2s;

         &:hover{
            color: $orange;
         }
      }
   }

   &__btnContainer {
      width: 100%; 
      margin: 0 0 2rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--btn {
         background: $light-gray;
         border-radius: 8px;
         width: 100%;
         // padding: 1rem 0;
         border: 1px solid $gray;
         box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
         cursor: pointer;

         & p{
            font-weight: 700;
            font-size: 1.8rem;
         }

         &:hover {
            background: $light-gray;
         }
      }

  

   }

}