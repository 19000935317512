.title {
   padding-bottom: 2rem;
   color: $white;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;

   @include query(max-w-550){
      align-items: center;
   }


   &__lineContainer {
      width: 30rem;
   }


   &__line {
         border-top: 1px solid $gray ;
      }
}